@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400&family=Inter&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,500&family=Mulish:wght@400;500&family=Raleway:ital,wght@0,300;0,400;1,300;1,400&family=Ubuntu:ital,wght@0,300;0,400;1,300;1,400&display=swap');

:root {
    --nav-width: 68px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* body {
    background-size: cover;
} */

.Register-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
}

.dashboard-influencer {
    height: 80vh;
}

.Register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.influencer_routes {
    background-image: url('../imgs//Old-Paper-Texture-3.jpg');
    background-size: cover;
    height: 100%;
}

.Heading p{
    font-family: 'Blacklisted', 'serif';
    letter-spacing: 4px;
    font-size: 2rem;
    font-weight: 100;
    color: #2E2E2E;
    text-decoration: underline;
}

.para {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Comfortaa', cursive;
    gap: 30px;
    font-size: 20px;
}

@font-face {
    font-family: 'Blacklisted';
    src: local('Blcaklisted'), url('../imgs/Blacklisted.ttf') format('truetype');
}

main {
    position: relative;
    margin: 3rem 0 0 0;
    font-size: 1rem;
    font-family: 'Blacklisted', 'serif';
    transition: 0.5s;
    background-color: #f1e4c7;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    height: 3rem;
    margin-top: 12px;
    width: 15vw;
    display: block;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 0 1rem;
    transition: .5s;
    padding: 5px;
    z-index: 10;
}

.icons {
    width: 50px;
}

.header-toggle {
    font-size: 1.25rem;
    cursor: pointer;
    color: #2E2E2E;
}

.sidebar {
    position: fixed;
    top: 0;
    left: -30%;
    height: 100vh;
    width: 15vw;
    padding-top: 1rem;
    transition: .5s;
    z-index: 10;
    box-shadow: 6px 13px 20px 9px #7f7d7d;
}

aside {
    padding: 0;
}

.nav-list span {
    display: flex;
    letter-spacing: 2px;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Blacklisted', 'serif';
}

.nava {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.nav-logo {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.nav-name {
    display: grid;
    grid-template-columns: max-content max-content;
    color: #2E2E2E;
    column-gap: 1rem;
    border-bottom: 1px solid #2E2E2E;
    padding: 15px 0;
}

.nav-logo {
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.nav-name {
    padding: -8px 5px;
}

.show {
    left: 0;
}

.space-toggle {
    padding-left: calc(var(--nav-width) + 1rem);
}

.nav-list {
    padding: 0;
}

.logo-head {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    align-items: center;
    margin-top: -100px;
}

.logoHead {
    background-color: transparent;
}

.signup-page {
    margin-top: -30px;
}

.human {
    cursor: pointer;
    width: 36px;
}

.carousel {
    width: 70%;
    height: 65vh;
    margin: auto;
    opacity: 2;
}

.carousel-indicators button[data-bs-target] {
    background: black;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 13px;
    height: 10px;
    border-radius: 50%;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

.dashboard-influencer .carousel-control-next-icon {
    position: relative;
    top: 42%;
    background-image: url("../imgs/arrow-forward-outline.svg");
}

.dashboard-influencer .carousel-control-prev-icon {
    position: relative;
    top: 42%;
    background-image: url("../imgs/arrow-back-outline.svg");
}

.skip {
    padding: 5px 35px;
    background-color: transparent;
}

.skip:hover {
    background-color: #2E2E2E;
    color: #f1e4c7;
    border: none;
    transition: all 0.5s;
}

.login-comp {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 100vh;
}

#form-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 12px;
}

#user {
    font-family: 'Blacklisted', 'serif';
    letter-spacing: 3px;
    font-size: 20px;
    font-weight: 500;
    color: #2E2E2E;
}

.input-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
}

.input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.fieldinput {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #2E2E2E;
    padding: 3px 25px;
    font-size: 18px;
    font-weight: 530;
    outline: none;
    width: 26vw;
    font-family: sans-serif;
}

#icon {
    position: absolute;
    left: 37%;
    cursor: pointer;
    color: #2E2E2E;
    font-size: 20px;
}

.input-field span {
    text-align: end;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    font-family: 'Comfortaa', cursive;
}

.btn-submit {
    width: 24vw;
    background-color: #2E2E2E;
    border: none;
    height: 45px;
    color: #f1e4c7;
    font-family: 'Blacklisted', 'serif';
    letter-spacing: 7px;
    cursor: pointer;
}

.partition {
    font-size: 16px;
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
}

.signin {
    text-align: center;
    margin-top: 12px;
    font-family: 'Comfortaa', cursive;
    font-size: 20px;
    font-weight: 700;
    color: #2E2E2E;
    /* letter-spacing: 1px; */
}

.button-grp {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 25px;
}

.google {
    background-color: #2E2E2E;
    padding: 4px 30px;
    color: #f1e4c7;
    border: none;
    font-family: 'Comfortaa', cursive;
    letter-spacing: 1px;
}

.privacies {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
    margin-top: 10%;
}

.privacies span {
    padding: 5px 5px;
    font-family: 'Comfortaa', cursive;
}

.create {
    /* text-align: center; */
    font-family: 'Comfortaa', cursive;
    margin-left: 69px;
    cursor: pointer;
}

.bold:hover {
    text-decoration: underline;
}

.signup {
    /* margin-top: -120px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.signup-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#header {
    text-align: center;
}

#header img {
    position: relative;
    width: 20vw;
    height: 6vh;
}

#header h2 {
    position: relative;
    bottom: 28px;
    font-size: 16px;
    letter-spacing: 3px;
    color: #f1e4c7;
    font-family: 'Blacklisted', 'serif';
}

#input-field {
    padding: 8px;
}

.user {
    font-size: 17px;
    letter-spacing: 2px;
}

.username {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
    /* width: 400px; */
}

.first {
    display: flex;
    gap: 10px;
}

.firstname {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 5px 2px;
    font-family: 'Comfortaa', cursive;
}

.lastname {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
}

.email {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
    /* width: 400px; */
}

.some {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.some input {
    outline: none;
}

.number {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
    width: 100%;
}

.address {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
    width: 66%;
}

.city {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
}

.state {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
    box-shadow: none;
    width: 200px;
}

.both {
    display: flex;
    gap: 10px;
}

.zip {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
    width: 66%;
}

.password {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
}

.re-password {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
}

.link {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
    /* width: 66%; */
}

.followers {
    border: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 15px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
    width: 100%;
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#btn {
    background-color: red;
    border: none;
    padding: 2px 19px;
    color: #f1e4c7;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
    margin-left: 8px;
}

#btn-2 {
    background-color: #5DB20C;
    border: none;
    padding: 4px 22px;
    color: #f1e4c7;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
    margin: 5px 0;
}

.link-page {
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-head {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 110vh;
    margin-top: -35px;
}

.link-header img {
    position: relative;
    width: 20vw;
    height: 6vh;
    bottom: 20px;
    left: 30%;
}

.link-header h2 {
    position: relative;
    font-size: 18px;
    bottom: 48px;
    left: 40%;
    letter-spacing: 3px;
    font-family: 'Blacklisted', serif;
    color: #f1e4c7;
}

#link-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
}

.link-input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    
}

.add-insta {
    display: flex;
}

.add-insta input {
    width: 29vw;
}

.add-insta ion-icon {
    cursor: pointer;
}

.user {
    font-family: 'Blacklisted', 'serif';
    letter-spacing: 3px;
    font-size: 17px;
    font-weight: 500;
    color: #2E2E2E;
    padding: 10px 0;
}

.link-insta {
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 16px;
    font-weight: 600;
    padding: 6px 2px;
    height: 30px;
    width: 30vw;
}

.link-input input:invalid {
    border: 1px solid red;
    border-radius: 12px;
}

.button-link1 {
    padding: 15px 0;
}

.button-link button {
    background-color: green;
    padding: 5px 25px;
    color: #f1e4c7;
    border: none;
    font-family: 'Blacklisted', serif;
    letter-spacing: 2px;
    border-radius: 12px;
}

@media (max-width: 280px) {
    .img-sec img {
        width: 30vw;
    }

    .info-sec {
        width: 50%;
    }

    .logo img {
        margin-left: 15px;
    }

    .info-sec .logoss {
        gap: 2px;
        padding: 15px 0;
    }

    .first-sec h4 {
        font-size: 15px;
    }

    #mid-sec .overall {
        margin: 0;
        justify-content: flex-start;
        padding: 10px 10px;
    }

    .facebook-section .right-item {
        width: 40vw;
        margin: 5px 0;
    }

    .overall .level h5 {
        letter-spacing: 2px;
        font-size: 15px;
    }

    .overall .hunt h5 {
        font-size: 15px;
        letter-spacing: 2px;
    }

    .hunts .completed {
        gap: 20px;
    }

    .overall .completed h5 {
        font-size: 15px;
        letter-spacing: 2px;
    }

    .overall .rate h5 {
        font-size: 15px;
    }

    .facebook-section {
        width: 80%;
    }

    .facebook-section .contentfacebook {
        width: 80%;
        padding: 0;
        margin: 0;
    }

    .contentfacebook .content {
        gap: 0px;
    }

    .facebook-section {
        flex-direction: column;
    }

    .first-sec h6 {
        font-size: 12px;
    }

    .first-sec span {
        font-size: 12px;
    }

    .data-show .photos img {
        width: 80vw;
    }

    .header {
        margin-top: 6px;
    }

    .sidebar {
        width: 30%;
        z-index: 1;
    }

    .header-toggle {
        margin-top: 15px;
    }

    .nav-list span {
        font-size: 12px;
    }

    .Heading p {
        font-size: 20px;
    }

    .para p {
        font-size: 15px;
    }

    .dashboard-influencer {
        height: 80vh;
    }

    .human {
        width: 30px;
    }

    .para {
        gap: 0;
    }

    .carousel {
        width: 100%;
        height: 65vh;
        margin-top: -35px;
    }

    #form-page {
        justify-content: center;
        align-items: flex-start;
        padding: 0px 25px;
    }

    #user {
        font-size: 17px;
    }

    #icon {
        left: 10px;
    }

    .fieldinput {
        width: 85vw;
    }

    #form-page button {
        margin: auto;
        width: 50vw;
    }

    /* .partition{
        margin:0 145px;
    } */
    .create {
        font-size: 13px;
    }

    .privacy {
        gap: 5px;
        text-decoration: underline;
    }

    .privacies span {
        font-size: 11px;
    }

    .signup {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 10px;
    }

    #header img {
        left: 8%;
        width: 75vw;
    }

    #header h2 {
        letter-spacing: 2px;
    }

    .some label {
        font-size: 16px;
    }

    #input-field {
        padding: 10px;
    }

    .username {
        width: 80vw;
        outline: none;
        font-size: 15px;
    }

    .firstname {
        width: 40vw;
        outline: none;
        font-size: 15px;
    }

    .lastname {
        width: 37vw;
        outline: none;
        font-size: 15px;
    }

    .email {
        width: 80vw;
        outline: none;
        font-size: 15px;
    }

    .city {
        width: 40vw;
        outline: none;
        font-size: 15px;
        /* gap: 1px; */
    }

    .state {
        width: 37vw;
        outline: none;
        font-size: 13px;
    }

    .password {
        width: 80vw;
        outline: none;
    }

    .re-password {
        width: 80vw;
        outline: none;
    }

    .number {
        width: 80vw;
        outline: none;
    }

    .link {
        width: 80vw;
        outline: none;
    }

    .followers {
        width: 80vw;
        outline: none;
    }

    #btn {
        display: none;
    }

    #btn-2 {
        margin: 0 15px;
    }

    .some #verifier {
        width: 34vw;
    }

    .link-page {
        justify-content: flex-start;
        align-items: flex-start;
        height: 120vh;
    }

    .link-head {
        margin-top: -65px;
        padding: 0 15px;
    }

    .link-header img {
        width: 65vw;
    }

    .link-header h2 {
        left: 60px;
        font-size: 15px;
        letter-spacing: 2px;
    }

    #link-profile {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .link-insta {
        width: 80vw;
        font-size: 12px;
    }

    #link-profile {
        gap: 25px;
    }

    .link-input label {
        font-size: 15px;
    }

    .profile-updation h2 {
        font-size: 20px;
    }

    #profile-up .update-page {
        margin: auto;
        padding: 10px;
        width: 50vw;
    }

    .update-user h5 {
        font-size: 15px;
    }

    .update-page .update-user input {
        width: 62vw;
    }

    .update-page .update-user1 select {
        width: 62vw;
    }

    .update-page .update-user1 h5 {
        font-size: 15px;
    }

    .update-page .update-category h5 {
        font-size: 15px;
    }

    .update-page .update-category select {
        width: 62vw;
    }

}

@media (min-width: 281px) and (max-width:400px) {
    .sidebar {
        width: 30vw;
    }

    .header-toggle {
        margin-top: 13px;
    }

    .profile-updation h2 {
        font-size: 22px;
    }

    #profile-up .update-page {
        margin: auto;
        padding: 10px;
        width: 50vw;
    }

    .human {
        width: 30px;
    }

    .update-user h5 {
        font-size: 18px;
    }

    .update-page .update-user input {
        width: 62vw;
    }

    .update-page .update-user1 select {
        width: 62vw;
    }

    .update-page .update-user1 h5 {
        font-size: 15px;
    }

    .update-page .update-category h5 {
        font-size: 15px;
    }

    .update-page .update-category select {
        width: 62vw;
    }

    #profile .img-sec img {
        width: 25vw;
    }

    #mid-sec .overall {
        margin: 0;
        justify-content: flex-start;
        padding: 20px 10px;
    }

    .overall .hunts h5 {
        font-size: 15px;
    }

    .facebook-section {
        width: 80%;
    }

    .facebook-section .contentfacebook {
        width: 80%;
        padding: 0;
        margin: 0;
    }

    .contentfacebook .content {
        gap: 0px;
    }

    .facebook-section {
        flex-direction: column;
    }

    .first-sec h6 {
        font-size: 14px;
    }

    .first-sec span {
        font-size: 14px;
    }

    .facebook-section .right-item {
        width: 60vw;
        margin: 5px 0;
    }

    .facebook-section .right-item span {
        letter-spacing: 2px;
    }

    .Heading p {
        font-size: 18px;
    }

    .Register {
        gap: 18px;
    }

    .para p {
        font-size: 17px;
    }

    .carousel {
        width: 80vw;
    }

    .input .fieldinput {
        width: 70vw;
    }

    .input #icon {
        left: 15%;
    }

    .btn-submit {
        width: 50vw;
    }

    #header img {
        width: 70vw;
        height: 7vh;
    }

    .some input {
        width: 70vw;
        margin: 0 5px;
        outline: none;
    }

    .both .city {
        width: 70vw;
        outline: none;
    }

    .both .state {
        width: 70vw;
        outline: none;
    }

    .first {
        flex-direction: column;
    }

    .both input {
        width: 25vw;
    }

    .both {
        flex-direction: column;
    }

    .form .some button {
        width: 26vw;
    }

    .link-page {
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100vh;
        gap: 0;
    }

    .link-header img {
        width: 60vw;
    }

    .link-header h2 {
        left: 50%;
    }

    .link-input input {
        width: 60vw;
    }

    .create {
        text-align: center;
        margin-left: 25px;
    }

    .privacies {
        gap: 14px;
    }

    .privacies span {
        font-size: 13px;
    }

    .nav-list span {
        font-size: 15px;
        letter-spacing: 3px;
    }

    .logo img {
        margin-left: 36px;
    }

    .written .instagram input {
        width: 200px;
    }
    .main-inn .first-second {
        flex-direction: column;
    }
    .main-inn .first-second input{
        width: 220px;
    }
    .main-inn .main-info input{
        width: 200px;
    }
    .main-inn .main-address input{
        width: 200px;
    }
    .main-inn .main-add {
        flex-direction: column;
    }
    .main-inn .main-add input{
        width: 200px;
    }
    .main-inn .main-add select{
        width: 200px;
    }
}

@media (min-width: 401px) and (max-width: 480px) {
    .profile-updation h2 {
        font-size: 22px;
    }

    #profile-up .update-page {
        margin: auto;
        padding: 10px;
        width: 50vw;
    }

    .update-user h5 {
        font-size: 18px;
    }

    .update-page .update-user input {
        width: 62vw;
    }

    .update-page .update-user1 select {
        width: 62vw;
    }

    .update-page .update-user1 h5 {
        font-size: 15px;
    }

    .update-page .update-category h5 {
        font-size: 15px;
    }

    .update-page .update-category select {
        width: 62vw;
    }

    #profile .img-sec img {
        width: 25vw;
    }

    #mid-sec .overall {
        margin: 0;
        justify-content: flex-start;
        padding: 20px 10px;
    }

    .overall .hunts h5 {
        font-size: 15px;
    }

    .facebook-section {
        width: 80%;
    }

    .facebook-section .contentfacebook {
        width: 80%;
        padding: 0;
        margin: 0;
    }

    .contentfacebook .content {
        gap: 0px;
    }

    .facebook-section {
        flex-direction: column;
    }

    .first-sec h6 {
        font-size: 14px;
    }

    .first-sec span {
        font-size: 14px;
    }

    .facebook-section .right-item {
        width: 60vw;
        margin: 5px 0;
    }

    .facebook-section .right-item span {
        letter-spacing: 2px;
    }

    .sidebar {
        width: 125px;
        z-index: 1;
    }

    .header-toggle {
        margin-top: 15px;
    }

    .nav-list span {
        font-size: 15px;
        letter-spacing: 3px;
    }

    .Heading p {
        font-size: 25px;
    }

    .para p {
        font-size: 17px;
    }

    .human {
        width: 36px;
    }

    .para {
        gap: 5px;
    }

    .carousel {
        width: 100%;
        height: 70vh;
        margin-top: -35px;
    }

    .Register {
        gap: 20px
    }

    #form-page {
        justify-content: center;
        align-items: flex-start;
        padding: 0px 15px;
    }

    #user {
        font-size: 20px;
    }

    #icon {
        left: 17%;
    }

    .fieldinput {
        width: 65vw;
    }

    #form-page button {
        margin: auto;
        width: 60vw;
    }

    #verifier {
        width: 24vw;
    }

    #veriy {
        width: 24vw;
    }

    /* .partition{
        margin:0 150px ;
    } */
    .create {
        margin: 0 15px;
    }

    .privacy {
        text-decoration: underline;
    }

    #header img {
        width: 70vw;
        height: 7vh;
    }

    #input-field {
        padding: 10px;
    }

    /* .link-page {
        justify-content: flex-start;
        align-items: flex-start;
    } */

    /* #link-profile {
        justify-content: flex-start;
        align-items: flex-start;
    } */

    .link-head {
        margin-left: 25px;
        padding: 0 15px;
    }

    .link-header img {
        width: 45vw;
    }

    .link-header h2 {
        letter-spacing: 3px;
        bottom: 50px;
        left: 48%;
    }

    .link-insta {
        width: 60vw;
        font-size: 15px;
    }

    #link-profile {
        gap: 30px;
    }

    .link-input label {
        font-size: 18px;
        letter-spacing: 4px;
    }
}

@media (min-width: 481px) and (max-width:600px) {
    .profile-updation h2 {
        font-size: 25px;
    }

    #profile-up .update-page {
        margin: auto;
        padding: 10px;
        width: 50vw;
    }

    .update-user h5 {
        font-size: 20px;
    }

    .update-page .update-user input {
        width: 62vw;
    }

    .update-page .update-user1 select {
        width: 62vw;
        padding: 6px 5px;
    }

    .update-page .update-user1 h5 {
        font-size: 21px;
    }

    .update-page .update-category h5 {
        font-size: 21px;
    }

    .update-page .update-category select {
        width: 62vw;
        padding: 6px 5px;
    }

    #profile .img-sec img {
        width: 25vw;
    }

    #mid-sec .overall {
        margin: 0;
        justify-content: flex-start;
        padding: 20px 10px;
    }

    .overall .hunts h5 {
        font-size: 15px;
    }

    .facebook-section {
        width: 80%;
    }

    .facebook-section .contentfacebook {
        width: 80%;
        padding: 0;
        margin: 0;
    }

    .contentfacebook .content {
        gap: 0px;
    }

    .first-sec h6 {
        font-size: 18px;
    }

    .first-sec span {
        font-size: 16px;
    }

    .facebook-section .right-item {
        width: 30vw;
        margin: 0 5px;
    }

    .facebook-section .right-item span {
        letter-spacing: 2px;
    }

    .sidebar {
        width: 135px;
        z-index: 1;
    }

    .header-toggle {
        margin-top: 15px;
    }

    .nav-list span {
        font-size: 15px;
        letter-spacing: 3px;
    }

    .Heading p {
        font-size: 25px;
    }

    .para p {
        font-size: 19px;
        letter-spacing: 1px;
        padding: 15px;
    }

    .human {
        width: 36px;
    }

    .carousel {
        width: 90%;
        height: 75vh;
        margin-top: -35px;
    }

    .Register {
        gap: 10px;
    }

    .para {
        gap: 5px;
    }

    .para p {
        padding: 5px 0;
    }

    #form-page {
        justify-content: center;
        align-items: flex-start;
        padding: 0px 15px;
    }

    #user {
        font-size: 22px;
    }

    #icon {
        left: 17%;
    }

    .fieldinput {
        width: 65vw;
    }

    #form-page button {
        margin: auto;
        width: 60vw;
    }

    /* .partition{
        margin:0 185px ;
    } */
    .create {
        margin: 0 75px;
    }

    .privacy {
        text-decoration: underline;
        margin: 80px 0;
    }

    #header img {
        width: 50vw;
        height: 7vh;
    }

    .link-page {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .link-head {
        margin-left: 25px;
        padding: 0 15px;
    }

    #link-profile {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .link-header img {
        width: 40vw;
        left: 40%;
    }

    .link-header h2 {
        bottom: 45px;
        left: 55%;
    }

    .link-insta {
        width: 60vw;
        font-size: 15px;
    }

    #link-profile {
        gap: 30px;
    }

    .link-input label {
        font-size: 18px;
        letter-spacing: 3px;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .profile-updation h2 {
        font-size: 25px;
    }

    #profile-up .update-page {
        margin: auto;
        padding: 10px;
        width: 50vw;
    }

    .update-user h5 {
        font-size: 20px;
    }

    .update-page .update-user input {
        width: 50vw;
    }

    .update-page .update-user1 select {
        width: 50vw;
        padding: 6px 5px;
    }

    .update-page .update-user1 h5 {
        font-size: 21px;
    }

    .update-page .update-category h5 {
        font-size: 21px;
    }

    .update-page .update-category select {
        width: 50vw;
        padding: 6px 5px;
    }

    .facebook-section .right-item {
        width: 30vw;
        margin: 0 5px;
    }

    .facebook-section .right-item span {
        letter-spacing: 2px;
    }

    .sidebar {
        width: 20vw;
        z-index: 1;
    }

    .header-toggle {
        margin-top: 15px;
    }

    .nav-list span {
        font-size: 15px;
        letter-spacing: 3px;
    }

    .Heading p {
        font-size: 25px;
    }

    .para p {
        font-size: 19px;
        letter-spacing: 1px;
        padding: 10px 15px;
    }

    .human {
        width: 36px;
    }

    .para {
        gap: 10px;
    }

    .carousel {
        width: 90%;
        height: 75vh;
        margin-top: -35px;
    }

    .Register {
        gap: 20px
    }

    .login-comp {
        height: 100vh;
    }

    #form-page {
        justify-content: center;
        align-items: flex-start;
        padding: 0px 15px;
    }

    #user {
        font-size: 22px;
    }

    #icon {
        left: 17%;
    }

    .fieldinput {
        width: 65vw;
    }

    #form-page button {
        margin: 10% auto;
        width: 60vw;
    }

    /* .partition{
        margin: 0 46% ;
    } */
    .login-page h4 {
        padding: 15px;
    }

    .create {
        margin: 0 22%;
    }

    .privacy {
        text-decoration: underline;
        margin: 80px 0;
    }

    #header img {
        width: 35vw;
        height: 7vh;
    }

    .link-page {
        justify-content: flex-start;
        align-items: flex-start;
    }

    #link-profile {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .link-head {
        margin-left: 25px;
        padding: 0 15px;
    }

    .link-header img {
        left: 50%;
        width: 40vw;
    }

    .link-header h2 {
        letter-spacing: 3px;
        left: 70%;
    }

    .link-insta {
        width: 60vw;
        font-size: 15px;
    }

    #link-profile {
        gap: 30px;
    }

    .link-input label {
        font-size: 18px;
        letter-spacing: 3px;
    }
}

@media (min-width: 769px) and (max-width: 900px) {
    .profile-updation h2 {
        font-size: 30px;
    }

    #profile-up .update-page {
        margin: auto;
        padding: 10px;
        width: 50vw;
    }

    .update-user h5 {
        font-size: 20px;
    }

    .update-page .update-user input {
        width: 40vw;
    }

    .update-page .update-user1 select {
        width: 40vw;
        padding: 6px 5px;
    }

    .update-page .update-user1 h5 {
        font-size: 21px;
    }

    .update-page .update-category h5 {
        font-size: 21px;
    }

    .update-page .update-category select {
        width: 40vw;
        padding: 6px 5px;
    }

    .facebook-section .contentfacebook {
        height: 21vh;
    }

    .sidebar {
        width: 20vw;
        z-index: 1;
    }

    .header-toggle {
        margin-top: 15px;
    }

    .nav-list span {
        font-size: 15px;
        letter-spacing: 3px;
    }

    .Heading p {
        font-size: 25px;
    }

    .para p {
        font-size: 19px;
        letter-spacing: 1px;
        padding: 10px 15px;
    }

    .human {
        width: 36px;
    }

    .para {
        gap: 10px;
    }

    .carousel {
        width: 80%;
        height: 75vh;
        margin-top: -35px;
    }

    .Register {
        gap: 20px
    }

    #form-page {
        justify-content: center;
        align-items: flex-start;
        padding: 0px 15px;
    }

    #user {
        font-size: 22px;
    }

    #icon {
        left: 25%;
    }

    .field {
        width: 50vw;
    }

    #form-page button {
        margin: 10% auto;
        width: 40vw;
    }

    /* .partition{
        margin: 0 46% ;
    } */
    .login-page h4 {
        padding: 15px;
    }

    .create {
        margin: 0 22%;
    }

    .privacy {
        text-decoration: underline;
        margin: 40px 0;
    }

    #header img {
        width: 35vw;
        height: 7vh;
    }

    .link-page {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .link-head {
        margin-left: 25px;
        padding: 0 15px;
    }

    #link-profile {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .link-header img {
        width: 30vw;
        left: 50%;
    }

    .link-header h2 {
        left: 65%;
    }

    .link-insta {
        width: 60vw;
        font-size: 15px;
    }

    #link-profile {
        gap: 30px;
    }

    .link-input label {
        font-size: 18px;
        letter-spacing: 3px;
    }
}

@media (min-width: 901px) and (max-width: 1024px) {
    #header img {
        width: 30vw;
        height: 7vh;
    }

    .link-header img {
        width: 25vw;
    }
}

@media (min-width: 1025px) and (max-width: 1225px) {
    #header img {
        width: 25vw;
        height: 7vh;
    }

    .link-header img {
        width: 21vw;
    }
}

#profile {
    margin: 0 auto;
    margin-top: -60px;
    border-bottom: 2px solid #2E2E2E;
}

.img-sec {
    border: 2px solid red;
    border-radius: 100px;
    margin-bottom: 5px;
}

.img-sec img {
    border-radius: 100px;

}

.upper-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.info-sec {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.info-sec span {
    padding: 4px 6px;
    font-family: 'Comfortaa', cursive;
    font-weight: 550;
}

.logoss {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    padding: 15px 10px;
}

.logoss a ion-icon {
    font-size: 25px;
    color: #474747;
}

.counts {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid #2E2E2E;
}

.first-sec {
    text-align: center;
    padding: 8px 0px;
}

.first-sec h4 {
    font-family: 'Comfortaa', cursive;
    padding: 5px;
}

.first-sec p {
    font-family: 'Comfortaa', cursive;
}

.overall {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 15px;
    font-family: 'Blacklisted', serif;
    padding: 15px 125px;
    border-bottom: 2px solid #2E2E2E;
}

.overall h5 {
    font-size: 17px;
    letter-spacing: 3px;
    font-family: 'Blacklisted','serif';
}

.level ion-icon {
    color: red;
    padding: 0 6px;
}

.level {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hunts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.completed {
    display: flex;
    gap: 65px;
}

.completed h5 {
    text-decoration: underline;
    cursor: pointer;
}

#countfb {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.facebook-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.contentfacebook {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    border: 2px solid #2E2E2E;
    width: 60%;
    height: 30vh;
    margin: auto;
}

.contentfacebook .logoandviews {
    width: 100%;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoandviews ion-icon {
    font-size: 30px;
}

.logoandviews span {
    font-family: 'Comfortaa', cursive;
    color: red;
}

.content {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
}

.content h6 {
    font-family: 'Comfortaa', cursive;
}

.content span {
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

.right-item {
    background-color: #2E2E2E;
    height: 15vh;
    color: #f1e4c7;
    display: flex;
    flex-direction: column;
    width: 15vw;
    align-items: flex-start;
    justify-content: space-around;
}

.right-item span {
    padding: 0 15px;
}

.showdata {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 100%;
    border-bottom: 1px solid #2E2E2E;
}

.data-insta {
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.image-data {
    border-radius: 100px;
    border: 2px solid red;
}

.image-data img {
    border-radius: 100px;
}

.about-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
}

.about-data {
    font-family: 'Comfortaa', cursive;
    text-transform: capitalize;
    font-size: 18px;
}

.getdata {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.getdata button {
    padding: 15px;
}

.data-show {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    /* flex-direction: column; */
    overflow: scroll;
}

.all-data {
    padding: 25px;
}

.all-data h5 {
    padding: 10px 10px;
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

.data-show::-webkit-scrollbar {
    display: none;
}

/* .photos{
    position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%
} */
.photos img {
    width: 30vw;
    padding: 0 30px;
    height: 40vh;
}

.update-page {
    margin: auto;
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    width: 45vw;
    border-radius: 12px;
    height: 80vh;
}

.update-user h5 {
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

.update-user input {
    background: transparent;
    border: none;
    padding: 5px 15px;
    width: 20vw;
    border-bottom: 1px solid #2E2E2E;
    font-family: 'Comfortaa', cursive;
    text-transform: uppercase;
}

.update-user1 h5 {
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

.update-category {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.update-image h5 {
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

.update-image input {
    font-family: 'Comfortaa', cursive;
    width: 20vw;
}

.update-category h5 {
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

select {
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-family: 'Comfortaa', cursive!important;
    width: 20vw;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .6);
    padding: 3px 5px;
}

option {
    background-color: #f1e4c7;
    font-family: 'Comfortaa', cursive;
}

.upload-image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.upload-image h5 {
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

.upload-image input {
    background-color: transparent;
    border: none;
}

.video-div h5 {
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
    padding: 10px 10px;
}

.video-div {
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.video-data {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    overflow: scroll;
}

.video-data::-webkit-scrollbar {
    display: none;
}

.submit-update button {
    background-color: #5DB20C;
    border: none;
    padding: 4px 22px;
    color: #f1e4c7;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
}
.update-page{
    height: 100%;
}
.update-page .update-username h5 {
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

.update-username input {
    background: transparent;
    border: none;
    padding: 5px 15px;
    width: 20vw;
    border-bottom: 1px solid #2E2E2E;
}

.some button {
    background-color: #5DB20C;
    border: none;
    padding: 4px 18px;
    color: #f1e4c7;
    border-radius: 12px;
    cursor: pointer;
    letter-spacing: 3px;
    width: 10vw;
    outline: none;
}

#profile-up {
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 12px;
    /* height: 100%; */
}

.profile-updation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-updation h2 {
    letter-spacing: 4px;
    padding: 10px 0;
    font-family: 'Blacklisted', 'serif';
    border-bottom: 2px solid #2E2E2E;
}

#forgot-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    height: 100vh;
    gap: 2%;
    width: 250px;
    margin: 0 auto;
}

.credd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #2E2E2E;
    border-radius: 20px 3px 20px 3px;
    padding: 35px;
    gap: 25px;
}

.forgot-password {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.forgot-password label {
    font-family: 'Blacklisted', serif;
    letter-spacing: 3px;
    font-size: 20px;
    padding: 15px 0;
}

.forgot-password input {
    font-family: 'Comfortaa', cursive;
    font-size: 18px;
    width: 250px;
}

.forgot-button button {
    width: 100px;
    height: 5vh;
    border-radius: 12px 0 12px 0;
    font-family: 'Comfortaa', cursive;
    font-size: 18px;
    font-weight: 600;
    border: none;
    background-color: #5DB20C;
}

.userData {
    position: relative;
}

.userData span {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #f1e4c7;
    translate: -50% -50%;
    font-family: 'Blacklisted', sans-serif;
    letter-spacing: 4px;
}

#sect {
    text-align: center;
}

#state {
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #2E2E2E;
    font-size: 15px;
    padding: 2px 2px;
    font-family: 'Comfortaa', cursive;
}

.about {
    height: 100vh;
    width: 100%;
    margin: 25px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.written {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px;
}

.instagram input {
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid #2E2E2E;
    font-size: 17px;
    font-weight: 600;
    padding: 6px 2px;
    width: 350px;
}

.instagram input:invalid {
    border: 1px solid red;
    border-radius: 12px;
}

.linksubmit {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

.linksubmit #addition {
    background-color: red;
    border: none;
    padding: 2px 19px;
    color: #f1e4c7;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
    margin-left: 8px;
}

.linksubmit #click {
    background-color: #5DB20C;
    border: none;
    padding: 4px 22px;
    color: #f1e4c7;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
}

.brand-logos {
    width: 100%;
    height: 100%;
}

.line {
    padding: 20px 0;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    text-align: center;
}

.brandss {
    font-size: 25px;
    font-family: 'Blacklisted', 'serif';
    letter-spacing: 3px;
}

#information-sec {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

#main-info-sec {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
}

.information-head {
    padding: 10px 0;
    text-align: center;
}

.information-head h2 {
    font-family: 'Blacklisted', 'serif';
    letter-spacing: 3px;
    text-decoration: underline;
}

.info-user {
    display: flex;
    flex-direction: column;
}

#label-info {
    font-family: 'Blacklisted', 'serif';
    font-size: 18px;
    letter-spacing: 3px;
    padding: 6px 0;
}

.main-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.main-info input {
    width: 320px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
    font-size: 18px;
    padding: 5px 0;
    outline: none;
    font-weight: 400;
}

.first-second {
    display: flex;
    gap: 8px;
}

.first-second input {
    width: 158px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
    font-size: 18px;
    padding: 5px 0;
    outline: none;
    font-weight: 400;
}

.main-inn {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#verification {
    background-color: #5DB20C;
    padding: 5px 15px;
    margin: 3px 0;
    color: white;
    border: none;
    outline: none;
    border-radius: 15px;
}

.submitButton {
    display: flex;
    justify-content: flex-end;
}

.submitButton button {
    background-color: #5DB20C;
    padding: 5px 25px;
    color: white;
    border: none;
    outline: none;
    border-radius: 15px;
    font-family: 'Blacklisted', 'serif';
    letter-spacing: 3px;
}

#table {
    width: 100%;
}

.ongoing-head {
    text-align: center;
    padding: 15px 0;
    font-family: 'Blacklisted', 'serif';
    letter-spacing: 3px;
}

#ongoing-component {
    height: 100%;
}

.main-address {
    display: flex;
    flex-direction: column;
}

.main-address input {
    width: 320px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
    font-size: 18px;
    padding: 5px 0;
    outline: none;
    font-weight: 400;
}
.main-add {
    display: flex;
    gap: 8px;
}
.main-add input {
    width: 158px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
    font-size: 18px;
    padding: 5px 0;
    outline: none;
    font-weight: 400;
}
.main-add select{
    padding: 8px 0;
    width: 158px;
}

iframe html body img{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.loaders{
z-index: 10;
width: 100%;
height: 100%;
justify-content:center;
align-items: center;
text-align: center;
display: flex;
position:absolute;
}

.overlay{
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2;
}

input[type="date"]{
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
}

.edit_btn
{
    border:1px solid #a7a4a4 !important;
    background-color: #a7a4a4 !important;
    padding: 0 10px !important;
}

.profilemain
{
    background-color: transparent;
}

.profilecard
{
    background-color: transparent !important;
    border: 1px solid #474747;
    border-radius: 10px;
    padding: 10px 10px;
}

.Edit_table input {
    background: transparent;
    border: none;
    padding: 5px 15px;
    width: 20vw;
    border-bottom: 1px solid #2E2E2E;
    font-family: 'Comfortaa', cursive;
    text-transform: uppercase;
}