 @import url('https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@100;200;300;400;500;600;700;800&family=Comfortaa:wght@300;400;600&display=swap');
@font-face {
  font-family:"Blacklisted" ;
  font-style:normal;
  font-weight: bold;
  src: url("./Fonts/Blacklisted.ttf");
}




.logo {
  width: 12rem;
  position: absolute;
  left: 760px;
  top: 2rem;
}
.card {
  --bs-card-spacer-y: 0rem;
}
  
.logo1 {
  width: 12rem;

}
.admin-panel-routes{
  background-image: url("./img/bg_paper.jpg");
  background-size: cover;
}

.containerr {
  width: auto ;
  height: 30vmin;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em 0;
  position: relative;
  font-size: 1.5rem;
  border-radius: 0.5em;
  background-color: #df1f2be4;
  border-bottom: 10px solid #18f98f;
}


.i1 {
  color: #18f98f;
  font-size: 2.5em;
  text-align: center;
}


.foot{
  background-color: rgba(189, 86, 86, 0.1);
  border-top-right-radius : 85px;
  border-top-left-radius : 85px;
}


span.num {
  color: rgb(255, 255, 255);
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 1.5em;
  padding-top: 5%;
}


span.text {
  color: hsl(0, 0%, 88%);
  font-size: 1em;
  text-align: center;
  padding: 0.2em 0;
  font-weight: 400;
   font-family: 'Comfortaa', cursive;
  /* line-height: 0; */
}


/* navlink color on click  */
.active {
  color: #bd5656;
}

.all{
  /* background-image: url("./img/bg_paper.jpg"); */
  background-size: cover;
}

.kard{
  border-radius: 2rem;
  border:1px solid #df1f2be4 ;
  background-color:rgba(231,208,174,0.6) ;
  box-shadow: #df1f2cb2 1px 2px 20px 3px;
}


#profile .field{
  color: red;
  box-shadow: #f4061653 0px 0px 0px 0.8px ;  
}

.fieldz{
  color: red;
  box-shadow: #f4061653 0px 0px 0px 0.8px ;
}

.butt{
  background-color:#E73838;
  color:white;
  width: 100%;
}

.fadeT{
  opacity: 0.7;
}


/* breadcrum */

.h6{
  margin-bottom: 0rem;
  line-height: 1.5;
}


.breadcrumb {
  --bs-breadcrumb-margin-bottom: 0rem;
}


.form-control:focus{
  border-color: #28a745;
  box-shadow: 0px 0px 3px 1px rgba(40,167,69,6.25);
}


.form input:hover{
cursor:pointer;
box-shadow: #ea122088 0px 0px 0px 0.8px ;
}




/* input field */
.form-control {
  padding: 0.2rem 0.4rem;
}


.field{
  color: red;
  box-shadow: #f4061653 0px 0px 0px 0.8px ;
}




.out{
  border: 2px solid rgb(254, 5, 5);
  border-radius: 50%;
  display: inline-block;
  font-size: 14px;
  height: 35px;
  text-align: center;
  width: 38px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.out:hover{
  box-shadow:  rgb(254, 5, 5) 0px 0px 2px 2px ;
  }

  .row>* {
  padding-right: calc(var(--bs-gutter-x) * 0);
}

.nav-tabs {

    --bs-nav-tabs-border-width: 1px;
    --bs-nav-tabs-border-color: #dee2e6;
    --bs-nav-tabs-border-radius: 0.375rem;
    --bs-nav-tabs-link-hover-border-color: #a3171c78 #a3171c35 #a3171c41;
    --bs-nav-tabs-link-active-color: #5e263e;
    --bs-nav-tabs-link-active-bg: #fff;
    --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
}

/* breadcrumb slash  */
.breadcrumb-item+.breadcrumb-item::before {
 float:none; 
}



/*======================
    404 page
=======================*/
.page_404{padding:20px 0; padding-bottom: 0%; background:#fff; font-family: 'Arvo', serif ;}
.page_404  img{ width:100%;}
.four_zero_four_bg{
 background-image: url('https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif');
 height: 100vh; background-position: center;} 
.four_zero_four_bg h1{font-size:100px;font-family: 'Blacklisted', bold;} 
.four_zero_four_bg h3{font-size:80px;}			 
.link_404{color: #fff!important;padding: 10px 20px;background: #39ac31;margin: 20px 0;display: inline-block;}
.contant_box_404{ margin-top:320px;}
/*======================
    404 page
=======================*/



/* Card  */
.card-circle{
  border: 0.5px solid #aaa;
  border-radius: 50%;
  display: inline-block;
  line-height: 22px;
  font-size: 12px;
  /* height: 25px; */
  text-align: center;
  width: 25px;
}
/* Card  */



.ard{
  border-radius: 2rem;
  border:1px solid #df1f2be4 ;
  background-color:rgba(231,208,174,0.3) ;
  box-shadow: #df1f2cb2 1px 2px 20px 3px;
}

.pikz{
  border-radius: 2rem;
  border:1px solid #df1f2be4 ;
  background-color:rgba(231,208,174,0.6) ;
  box-shadow: #df1f2cb2 1px 2px 20px 3px;
}





/* ___________________BArchart/testing.jsx_______________________________ */




/* body {  
  background: #1D1F20;
  padding: 16px;
} */

canvas .testBar{
  border: 1px dotted red;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 50vh;
  width: 50vw;
}




/* __________________________________________________ */