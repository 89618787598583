@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400&family=Inter&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,500&family=Mulish:wght@400;500&family=Raleway:ital,wght@0,300;0,400;1,300;1,400&family=Ubuntu:ital,wght@0,300;0,400;1,300;1,400&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: 'Blacklisted';
  src: local('Blacklisted'), url('./Blacklisted.ttf') format('truetype');
}

.bar{
  display: flex;
  justify-content:center;
}
.bar img{
  width: 100px;
  height: 50px;
}
.headd{
  margin-top: 50px;
}
.options{ 
  padding: 240px;
  font-size: 30px;
  font-family: 'Blacklisted', serif;
  color: rgba(20, 12, 12, 0.759);
}
.rule{
  border-top: 2px solid rgba(47, 44, 44,0.5);
  color: black;
  font-size: 100px;
}
.mainm{
  width: 100%; 
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
}
.B_logo{
   width: 100px;
   padding: 10px;
}
.lays-logo{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.B_desc{
  width: 400px;
  height: 70%;
  margin: 15px 0; 
}
.hash{
  display: flex;
  justify-content: center;
  padding-top: 15px;
  font-size: 50px;
  font-family: 'Blacklisted', serif;
  color: #2E2E2E;
}
.social{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.social img{
  width: 30px;
}
.social span{
  background-color: #FF2E2E;
  padding: 2px 10px;
  color: #f1e4c7;
  cursor: pointer;
}
.para{
  font-size: 20px;
  font-family: 'Comfortaa', cursive;
  color: rgba(20, 12, 12, 0.8);
  font-weight: 600;
}
.paraM{
  padding: 10px;
}
.prize{
  margin: auto;
}
.prize button{
  background-color:#FF2E2E ;
  padding: 2px 10px;
  cursor: pointer;
  color: #f1e4c7;
  border: none;
  font-family: 'Blacklist', serif;
  font-size: 25px;
} 
.menu {
  position:absolute;
  z-index: 10;
  list-style-type:none;
  margin: 10px 10px;
  padding: 10;  
  margin-left: 350px;
  margin-top: 5px;;
  width: 180px;
  border: 1px solid black;
}

.menu  > li {
  margin: 0;  
  background-color:rgba(228, 209, 180, 0.900);
}

.menu  > li:hover {
  background-color: rgb(211,172,116);
} 

.line1 > img:hover {
  background-color: rgb(211,172,116);
}

.menu  > li > button {
    width: 100%;
    height: 100%;
    text-align: left;   
    background: none;
    color: inherit;
    border: none;
    padding: 15px;
    margin: 0;
    font: inherit;
    font-size: 23px;
    cursor: pointer;
  } 
  .hell2{
    position: absolute;
    margin-left: -372px;
    margin-top: 40px;
  }

  .hell3{
    position: absolute;
    margin-left: -372px;
    margin-top: 40px;
  }
  .req{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 15px 60px;
    margin-top: -90px;
  }
  
  .req h3{
    font-size: 20px;
    font-family: 'Blacklisted', serif;
    color: #2E2E2E;
    margin-top: 35px;
    letter-spacing: 2px;
    font-weight: 500;
  }

  .req span{
    font-size: 17px;
    font-family: 'Comfortaa', cursive;
    color: #2E2E2E;
    font-weight: 500;
  }
  .maindiv{
    display: flex;
    justify-content: center;   
  }
  
  .maindiv span{
    font-size: 30px;
    font-family: 'Blacklisted', serif;
    color: rgba(20, 12, 12, 0.759);
  }
  
  .line1{
    display: flex;
    justify-content: space-around;
    margin: 80px;
    align-items: center;
  }

  .Lays_l , .cheetos_l{
    width: 200px;
  }

  .rulee{
    margin-top: 50px;
    border-top: 2px solid rgba(47, 44, 44,0.5);
    color: black;
    font-size: 100px;
  }
  .dropdown {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #2E2E2E;
    border-bottom: 1px solid #2E2E2E;
  }
  .dropdown .dropdown-btn{
    padding: 10px 18px;
    background-color: transparent;
    font-weight: bold;
    cursor: pointer;
    color: #2E2E2E;
    display: flex;
    font-family:'Blacklisted', serif ;
    letter-spacing: 2px;
    align-items: center;
    font-size: 20px;
  }
  #dropdown-sec{
    margin-top: -25px;
    padding-bottom:25px ;
  } 
  .dropdown .dropdown-content{
    position: absolute;
    top: 110%;
    left: 180px;
    padding:10px 8px 0 8px;
    background: #f1e4c7;
    font-family: 'Blacklisted', serif;
    font-weight: 500;
    font-size: 12px;
    color: #2E2E2E;
    cursor: pointer;
    width: 40%;
    z-index: 999;
    overflow: scroll;
  }
  .dropdown-content::-webkit-scrollbar {
    display: none;
  }
  #dropdown-con{
    width: 50%;
  }
  .dropdown .dropdown-content .dropdown-item {
    padding: 10px 2px;
    letter-spacing: 2px;
    border-bottom: 1px solid #2E2E2E;
    font-family: 'Blacklisted','serif';
  }
  .dropdown-section{
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .selection{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .selection p{
    font-size: 25px;
    font-family: 'Comfortaa', serif;
    font-weight: 600;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    padding: 10px;
    letter-spacing: 3px;
  }
  @media (max-width:  320px) {    
    .dropdown .dropdown-btn{
      font-size: 13px;
      font-size: 13px;
      padding: 6px 0px;
    }
     .dropdown .dropdown-content{
      left: 10%;
      width: 100%;
      padding: 10px 0;
    }
    #dropdown-con{
      width: 65vw;
      left: 0;
    }
    .dropdown .dropdown-content .dropdown-item {
      padding: 6px 2px;
      letter-spacing: 1px;
    }
    #plat{
      left: 10%;
    }
    .B_logo{
      z-index: 1;
      width: 35%;
      margin-top: 55px;
    }
    .B_desc{
      width: 95%;
    }
    .mainm{
      height: 105vh;
    }
    .mainm h1{
      font-size: 32px;
      letter-spacing: 4px;
    } 
    .social img{
      width: 25px;
    }
    .paraM{
      padding: 0;
    }
    .para{
      font-size: 18px;
      margin-top: 15px;
    }
  }
  @media (min-width: 321px) and (max-width:400px){
    .dropdown .dropdown-btn{
      font-size: 16px;
    }
    .dropdown .dropdown-content{
      left: 10%;
      width: 100%;
    }
    #dropdown-con{
      width: 65vw;
      left: 0;
    }
    .dropdown .dropdown-content .dropdown-item {
      padding: 6px 2px;
      letter-spacing: 1px;
    }
    #plat{
      left: 10%;
    }
    .B_desc{
      width: 98%;
    }
    .dropdown .dropdown-btn {
      font-size: 16px;
      padding: 10px 15px;
    }
    .paraM p{
      font-size: 17px;
    }
    .hash{
      font-size: 45px;
    }
  }
  @media (min-width: 401px) and (max-width: 480px){
    .mainm{
      height: 110vh;
    }
    .dropdown-btn{
      padding: 0;
      font-size: 17px;
      padding: 0px 5px;
    }
      .dropdown .dropdown-content{
      left: 10%;
      width: 100%;
      padding: 2px;
    }
    #dropdown-con{
      width: 50vw;
      left: 0;
    }
    .dropdown .dropdown-content .dropdown-item {
      padding: 2px 5px;
      font-size: 13px;
    }
    .B_logo{
      z-index: 1;
      width: 35%;
      margin-top: 55px;
    }
    .B_desc{
      width: 95%;
    }
    .mainm h1{
      font-size: 42px;
      letter-spacing: 4px;
    } 
  }
  @media (min-width: 481px) and (max-width:600px){
    .dropdown-btn{
      padding: 0;
      font-size: 20px;
      font-weight: 600;
      padding: 0px 5px;
    }
    .dropdown .dropdown-content{
      left: 10%;
      width: 100%;
      padding: 2px;
    }
    #dropdown-con{
      width: 50vw;
      left: 0;
    }
    .dropdown .dropdown-content .dropdown-item {
      padding: 2px 5px;
      font-size: 13px;
    }
    .B_logo{
      z-index: 1;
      width: 35%;
      margin-top: 30%;
    }
    .B_desc{
      width: 95%;
    }
    .mainm h1{
      font-size: 42px;
      letter-spacing: 4px;
    }
  }
  @media (min-width: 601px) and (max-width: 768px){
    .dropdown-btn{
      padding: 0;
      font-size: 20px;
      padding: 0px 5px;
    }
    .dropdown .dropdown-content{
      left: 10%;
      width: 100%;
      padding: 2px;
    }
    .dropdown .dropdown-content .dropdown-item {
      padding: 2px 5px;
      font-size: 13px;
    }
    .B_logo{
      z-index: 1;
      width: 35%;
      margin-top: 40%;
    }
    .B_desc{
      width: 95%;
      height: 70vh;
    }
    .mainm h1{
      font-size: 40px;
      margin-top: 18px;
      letter-spacing: 4px;
    }
  }
  @media (min-width: 769px) and (max-width: 900px){
    .dropdown-btn{
      padding: 0;
      font-size: 20px;
      padding: 0px 5px;
    }
     .dropdown .dropdown-content{
      left: 10%;
      width: 100%;
      padding: 2px;
    }
    .dropdown .dropdown-content .dropdown-item {
      padding: 2px 5px;
      font-size: 13px;
    }
    .B_logo{
      z-index: 1;
      width: 35%;
      margin-top: 40%;
    }
    .B_desc{
      width: 95%;
      height: 70vh;
    }
    .mainm h1{
      font-size: 40px;
      margin-top: 18px;
      letter-spacing: 4px;
    } 
  }
  /* @media (min-width: 901px) and (max-width: 1024px){
    .dropdown-btn{
      padding: 0;
      font-size: 20px;
      padding: 0px 5px;
    }
     .dropdown .dropdown-content{
      left: 10%;
      width: 100%;
      padding: 2px;
    }
     .dropdown .dropdown-content .dropdown-item {
      padding: 2px 5px;
      font-size: 13px;
    }
    .B_logo{
      z-index: 1;
      width: 35%;
      margin-top: 40%;
    }
    .B_desc{
      width: 100%;
      height: 70vh;
    }
    .mainm h1{
      font-size: 40px;
      margin-top: 18px;
      letter-spacing: 4px;
    }
  }
  @media (min-width: 1025px) and (max-width: 1200px){
    .dropdown-btn{
      padding: 0;
      font-size: 20px;
      padding: 0px 5px;
    }
    .dropdown .dropdown-content{
      left: 10%;
      width: 100%;
      padding: 2px;
    }
    .dropdown .dropdown-content .dropdown-item {
      padding: 2px 5px;
      font-size: 13px;
    }
    .B_logo{
      z-index: 1;
      width: 35%;
      margin-top: 40%;
    }
    .B_desc{
      width: 100%;
      height: 70vh;
    }
    .mainm h1{
      font-size: 40px;
      margin-top: 18px;
      letter-spacing: 4px;
    } 
  }
  @media (min-width: 1201px) and (max-width: 1400px){
    .dropdown-btn{
      padding: 0;
      font-size: 20px;
      padding: 0px 5px;
    }
     .dropdown .dropdown-content{
      left: 10%;
      width: 60%;
      padding: 2px;
    }
    .dropdown .dropdown-content .dropdown-item {
      padding: 2px 5px;
      font-size: 13px;
    }
    .B_logo{
      z-index: 1;
      width: 35%;
      margin-top: 40%;
    }
    .B_desc{
      width: 100%;
      height: 70vh;
    }
    .mainm h1{
      font-size: 40px;
      margin-top: 18px;
      letter-spacing: 4px;
    } 
  } */
  .campaign-carousel{
    height: auto;
  }
  .carousel-control-next-icon{
    position: relative;
    top: 42%;
    background-image: url("../../imgs/arrow-forward-outline.svg")!important;
  }
  .carousel-control-prev-icon{
    position: relative;
    top: 42%;
    background-image: url("../../imgs/arrow-back-outline.svg")!important;
  }