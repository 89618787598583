.modal-content{
    width: 90vw !important;
    padding: 10px !important;
}

.logo_admin
{
    width: 50px;
    height: 60px;
    border-radius: 20px;
}

.modal-dialog
{
 margin: var(--bs-modal-margin) !important;
}